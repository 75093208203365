// コメント位置はPrettier対策
class AppCertification {
  //FIXME 本番設定に書き換えてください。
  /**
   * アプリケーションの定数値です。
   */
  APPVERSION = "24.08.01";
  APP_CONFIG = {
    //FIXME MockModeの有効無効
    // HttpClientのgetを対象として、dummy.jsonを読み込むフラグです。
    MOCK_MODE: false,

    /**
     * API接続用設定（VDI外アクセス）
     */
    // DE環境(SV03）
    BASE_URL: "https://cirrus-tm-dev.nikkon-is.com/",
  };

  /**
   * Cognitoの設定です。
   */
  //FIXME 本番設定に書き換えてください。
  //営業所用
  COGNITO = {
    USER_POOL_ID: "ap-northeast-1_Wwo7UdFv1",
    APP_CLIENT_ID: "3q3lieo026djuv4u1actnpvv8v",
  };

  //荷主用
  SHIPPER_COGNITO = {
    USER_POOL_ID: "ap-northeast-1_jHhbolQzz",
    APP_CLIENT_ID: "1o9orppcedu3tpsmcdivuofe66",
  };

  /** datadog */
  DATADOG = {
    DD_APPLICATION_ID: "f5dbf4a7-ef77-4572-9829-ade93e2b6c3e",
    DD_CLIENT_TOKEN: "pubf4878a93b267ad4433faf5fa86204eba",
    DD_SERVICE: "wms-de-pc",
    DD_ENV: "wms-de",
    DD_SESSION: 20,
  };
  /** datadog */
}

export const appCertification = new AppCertification();
